/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Dashboards
05.Pages
06.Applications
  06.01.Chat
  06.02.Survey
  06.03.Todo
07.UI
  07.01.Alerts
  07.02.Badges
  07.03.Buttons
  07.04.Cards
  07.05.Carousel
  07.06.Charts
  07.07.Collapse
  07.08.Dropdowns
  07.09.Editors
  07.10.Forms
  07.11.Form Components
  07.12.Icons
  07.13.Input Groups
  07.14.Jumbotron
  07.15.Modal
  07.16.Navigation
  07.17.Popover & Tooltip
  07.18.Sortable
  07.19.Maps
  07.20.Tables
  07.21.Wizards
*/

module.exports = {
  APROVED: 'Aprovado',
  PENDING: 'Pendente',
  CANCELLED: 'Cancelado',
  active: 'Ativo',
  inactive: 'Inativo',
  deleted: 'Excluído',
  checkFuelType: 'Bloquear abastecimento de veículos por tipo de combustível',
  checkAvailablePremiumFuel:
    'Bloquear abastecimento de veículos com combustíveis aditivados',
  transactionLimit: 'Valor limite de transação',
  efficiencyFuelBlock: 'Bloquear abastecimento de veículos por eficiência',
  sharedBalanceMaxDisplay:
    'Valor máximo exibido no aplicativo motorista caso o saldo seja compartilhado',
  anywhereReceiptReadingRequired:
    'Exigir validação de cupom fiscal em abastecimentos Litroz Livre',
  'User already exists.': 'Este usuário já foi cadastrado.',
  'Email already exists.': 'Este e-mail já foi cadastrado.',
  'Vehicle already exists.': 'Este veículo já foi cadastrado.',
  'Container already exists.': 'Este recipiente já foi cadastrado.',
  'Recipient already exists.': 'Este beneficiário já foi cadastrado.',
  'Driver already exists.': 'Este condutor já foi cadastrado.',
  daily: 'Diário',
  weekly: 'Semanal',
  monthly: 'Mensal',
  vehicle: 'Veículo',
  driver: 'Condutor',
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Cadastrar',
  'user.forgot-password': 'Esqueci minha senha',
  'user.email': 'E-mail',
  'user.password': 'Senha',
  'user.forgot-password-question': 'Esqueceu sua senha?',
  'user.fullname': 'Full Name',
  'user.login-button': 'ENTRAR',
  'user.register-button': 'CADASTRAR',
  'user.reset-password-button': 'RECUPERAR',
  'user.buy': 'COMPRAR',
  'user.buy-credits': 'COMPRAR CRÉDITOS',
  'user.username': 'Usuário',
  'user.new-password-again': 'Confirmar nova senha',
  'user.new-password': 'Nova senha',
  'user.reset-password': 'Recuperar Senha',
  'user.logout': 'Sair',

  /* 03.Menu */
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.default': 'Default',
  'menu.analytics': 'Analytics',
  'menu.ecommerce': 'Ecommerce',
  'menu.content': 'Content',
  'menu.actions': 'Ações',
  'menu.entries': 'Cadastros',
  'menu.stations': 'Postos',
  'menu.map': 'Mapa',
  'menu.vehicle': 'Veículos',
  'menu.container': 'Recipientes',
  'menu.bills': 'Faturas',
  'menu.branches': 'Filiais',
  'menu.admins': 'Administradores',
  'menu.driver': 'Condutores',
  'menu.recipient': 'Beneficiários',
  'menu.transactions': 'Transações',
  'menu.reports': 'Relatórios',
  'menu.deposits': 'Depósitos',
  'menu.settings': 'Configurações',
  'menu.fill-report': 'Relatório - Abastecimentos',
  'menu.pages': 'Pages',
  'menu.data-list': 'Data List',
  'menu.thumb-list': 'Thumb List',
  'menu.image-list': 'Image List',
  'menu.details': 'Details',
  'menu.search': 'Buscar',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.forgot-password': 'Forgot Password',
  'menu.error': 'Error',
  'menu.applications': 'Applications',
  'menu.todo': 'To-do List',
  'menu.survey': 'Survey',
  'menu.chat': 'Chat',
  'menu.ui': 'UI',
  'menu.alerts': 'Alerts',
  'menu.badges': 'Badges',
  'menu.buttons': 'Buttons',
  'menu.cards': 'Cards',
  'menu.carousel': 'Carousel',
  'menu.charts': 'Charts',
  'menu.collapse': 'Collapse',
  'menu.dropdowns': 'Dropdowns',
  'menu.editors': 'Editors',
  'menu.form-layouts': 'Form Layouts',
  'menu.form-components': 'Form Components',
  'menu.form-validations': 'Form Validations',
  'menu.form-wizard': 'Form Wizard',
  'menu.icons': 'Icons',
  'menu.input-groups': 'Input Groups',
  'menu.jumbotron': 'Jumbotron',
  'menu.modal': 'Modal',
  'menu.navigation': 'Navigation',
  'menu.popover-tooltip': 'Popover & Tooltip',
  'menu.sortable': 'Sortable',
  'menu.tables': 'Tables',
  'menu.menu': 'Menu',
  'menu.subhidden': 'Subhidden',
  'menu.hidden': 'Hidden',
  'menu.visible': 'Visible',
  'menu.maps': 'Maps',
  'menu.landingpage': 'Landing Page',
  'menu.multipage-home': 'Multipage Home',
  'menu.singlepage-home': 'Singlepage Home',
  'menu.about': 'About',
  'menu.auth-login': 'Auth Login',
  'menu.auth-register': 'Auth Register',
  'menu.reset-password': 'Reset Password',
  'menu.blog': 'Blog',
  'menu.blog-list': 'Blog List',
  'menu.blog-detail': 'Blog Detail',
  'menu.careers': 'Careers',
  'menu.confirmation': 'Confirmation',
  'menu.contact': 'Contact',
  'menu.docs': 'Docs',
  'menu.features': 'Features',
  'menu.prices': 'Prices',
  'menu.videos': 'Videos',
  'menu.mailing': 'Mailing',
  'menu.invoice': 'Invoice',
  'menu.blank-page': 'Blank Page',
  'menu.types': 'Menu Types',
  'menu.levels': 'Menu Levels',
  'menu.third-level-1': 'Third Level 1',
  'menu.third-level-2': 'Third Level 2',
  'menu.third-level-3': 'Third Level 3',
  'menu.faq': 'Faq',
  'menu.knowledge-base': 'Knowledge Base',
  'menu.authorization': 'Authorization',
  'menu.profile': 'Profile',
  'menu.product': 'Product',
  'menu.miscellaneous': 'Miscellaneous',
  'menu.portfolio': 'Portfolio',
  'menu.social': 'Social',
  'menu.details-alt': 'Details Alt',
  'menu.forms': 'Forms',
  'menu.components': 'Components',
  'menu.layouts': 'Layouts',
  'menu.validations': 'Validations',
  'menu.wizard': 'Wizard',
  'menu.list': 'Lista',
  'menu.balance': 'Crédito',
  'menu.distribution': 'Distribuição',
  'menu.balance-sharing': 'Créditos Adicionados',
  'menu.efficiency': 'Eficiência',
  'menu.change-password': 'Alterar Senha',
  'menu.password': 'Senha',
  'menu.change': 'Alterar',
  'menu.access': 'Acessos',
  'menu.security': 'Segurança',
  'menu.users': 'Usuários',
  'menu.permissions': 'Permissões',
  'menu.attachments': 'Anexos',
  'menu.download': 'Download',
  'menu.fuel-management': 'Tanque Interno',
  'menu.statement': 'Extrato',
  'menu.:id': ':id',
  'menu.purchase': 'Compras',
  'menu.expense-center': 'Centro de Custo',
  'menu.expense-center-distribution': 'Distribuição',
  'menu.tank': 'Tanque',
  'menu.wallets': 'Carteiras',
  'menu.tanks': 'Tanques',
  'menu.route-script': 'Roteirizador',
  'menu.schedules': 'Agendamentos',
  'menu.create': 'Criar',
  'menu.edit': 'Editar',
  'menu.admin': 'Administradores',

  /* 04.Dashboards */
  'dashboards.pending-orders': 'Pending Orders',
  'dashboards.completed-orders': 'Completed Orders',
  'dashboards.refund-requests': 'Refund Requests',
  'dashboards.new-comments': 'New Comments',
  'dashboards.sales': 'Sales',
  'dashboards.orders': 'Orders',
  'dashboards.refunds': 'Refunds',
  'dashboards.recent-orders': 'Recent Orders',
  'dashboards.product-categories': 'Product Categories',
  'dashboards.cakes': 'Cakes',
  'dashboards.tickets': 'Tickets',
  'dashboards.calendar': 'Calendar',
  'dashboards.best-sellers': 'Best Sellers',
  'dashboards.website-visits': 'Website Visits',
  'dashboards.unique-visitors': 'Unique Visitors',
  'dashboards.this-week': 'This Week',
  'dashboards.last-week': 'Lat Week',
  'dashboards.this-month': 'This Month',
  'dashboards.conversion-rates': 'Conversion Rates',
  'dashboards.per-session': 'Per Session',
  'dashboards.profile-status': 'Profile Status',
  'dashboards.payment-status': 'Payment Status',
  'dashboards.work-progress': 'Work Progress',
  'dashboards.tasks-completed': 'Tasks Completed',
  'dashboards.payments-done': 'Payments Done',
  'dashboards.order-stock': 'Order - Stock',
  'dashboards.categories': 'Categories',
  'dashboards.quick-post': 'Quick Post',
  'dashboards.title': 'Title',
  'dashboards.content': 'Content',
  'dashboards.category': 'Category',
  'dashboards.save-and-publish': 'Save and Publish',
  'dashboards.top-viewed-posts': 'Top Viewed Posts',
  'dashboards.posts': 'Posts',
  'dashboards.pending-for-publish': 'Pending for publish',
  'dashboards.users': 'Users',
  'dashboards.on-approval-process': 'On approval process',
  'dashboards.alerts': 'Alerts',
  'dashboards.waiting-for-notice': 'Waiting for notice',
  'dashboards.files': 'Files',
  'dashboards.pending-for-print': 'Pending for print',
  'dashboards.logs': 'Logs',
  'dashboards.gogo': 'GOGO',
  'dashboards.magic-is-in-the-details': 'MAGIC IS IN THE DETAILS',
  'dashboards.yes-it-is-indeed': 'Yes, it is indeed!',
  'dashboards.advanced-search': 'Advanced Search',
  'dashboards.toppings': 'Toppings',
  'dashboards.type': 'Type',
  'dashboards.keyword': 'Keyword',
  'dashboards.search': 'Search',
  'dashboards.top-rated-items': 'Top Rated Items',
  'dashboards.balance': 'Créditos disponíveis',
  'dashboards.users-balance': 'Créditos distribuídos',
  'dashboards.consumed-balance': 'Créditos utilizados (mês)',
  'dashboards.last-purchase': 'Data última compra',
  'dashboards.fuelRatio': 'Proporção de combustível',
  'dashboards.activeRecords': 'Cadastros Ativos',
  'dashboards.invoiceProgression': 'Progressão de Fatura',
  'dashboards.wateringProgression': 'Progressão de Litragem',

  /* 05.Pages */
  'pages.add-new': 'ADD NEW',
  'pages.add': 'ADICIONAR',
  'pages.add-new-modal-title': 'Novo Item',
  'pages.display-options': 'Filtros',
  'pages.orderby': 'Ordenar por : ',
  'pages.filterby': 'Filtrar por : ',
  'pages.product-name': 'Product Name',
  'pages.license-plate': 'Placa',
  'pages.make': 'Marca',
  'pages.name': 'Nome',
  'pages.surname': 'Sobrenome',
  'pages.cpf': 'CPF',
  'pages.mail': 'E-mail',
  'pages.company': 'Empresa',
  'pages.phone': 'Telefone',
  'pages.app-pass': 'Senha do Aplicativo',
  'pages.confirm-app-pass': 'Confirmar Senha do Aplicativo',
  'pages.transaction-pass': 'Senha da Transação',
  'pages.confirm-transaction-pass': 'Confirmar Senha da Transação',
  'pages.status': 'Status',
  'pages.cnpj': 'CNPJ',
  'pages.fantasy-name': 'Nome Fantasia',
  'pages.cep': 'CEP',
  'pages.website': 'Website',
  'pages.number': 'Número',
  'pages.city': 'Cidade',
  'pages.state': 'Estado',
  'pages.neighborhood': 'Bairro',
  'pages.street': 'Logradouro',
  'pages.model': 'Modelo',
  'pages.year': 'Ano',
  'pages.photo': 'Foto',
  'pages.color': 'Cor',
  'pages.tank': 'Capacidade do tanque (litros)',
  'pages.social-name': 'Razão Social',
  'pages.km': 'Média esperada (km/l)',
  'pages.odometer': 'Odômetro',
  'pages.category': 'Categoria',
  'pages.current-balance': 'Saldo Atual',
  'pages.share-balance': 'Compartilhar saldo',
  'pages.current-credit': 'Crédito Atual',
  'pages.share-credit': 'Compartilhar crédito',
  'pages.add-credit': 'Crédito a ser adicionado',
  'pages.collect-credit': 'Recolher crédito',
  'pages.change-status': 'Alterar status',
  'pages.value': 'Valor',
  'pages.save': 'Salvar',
  'pages.vehicle-type': 'Tipo',
  'pages.fuel': 'Combustível',
  'pages.cost': 'Preço',
  'pages.liters': 'Litros',
  'pages.total': 'Total',
  'pages.of': 'de',
  'pages.to': 'a',
  'pages.station': 'Posto',
  'pages.vehicle': 'Veículo',
  'pages.container': 'Recipiente',
  'pages.id': 'ID',
  'pages.user': 'Usuário',
  'pages.admin': 'Administrador',
  'pages.notes': 'Observações',
  'pages.date': 'Data',
  'pages.created-at': 'Data de criação',
  'pages.paid-at': 'Data de pagamento',
  'pages.date-interval': 'Data',
  'pages.description': 'Descrição',
  'pages.cancel': 'Cancelar',
  'pages.confirm': 'Confirmar',
  'pages.see-on-map': 'Ver no mapa',
  'pages.sure-to-delete':
    'Tem certeza que deseja excluir o registro selecionado?',
  'pages.sure-to-collect-credit':
    'Tem certeza que deseja estornar {value} de {name}?',
  'pages.sure-to-collect-vehicle-credit':
    'Tem certeza que deseja estornar {value} do veículo {licensePlate}?',
  'pages.change-status-warning':
    'Ao inativar {identifier} o saldo R$ {value} será estornado para empresa. Deseja continuar?',
  'pages.sure-to-covert-recipient':
    'Tem certeza que deseja converter {name} em beneficiário?',
  'pages.sure-to-convert-driver':
    'Tem certeza que deseja converter {name} em condutor?',
  'pages.submit': 'Salvar',
  'pages.delete': 'Deletar',
  'pages.ok': 'OK',
  'pages.ops': 'Ops :(',
  'pages.error': 'Falha na operação. Tente novamente mais tarde.',
  'pages.balance': 'Saldo',
  'pages.credit': 'Crédito',
  'pages.another-action': 'Another action',
  'pages.actions': 'Actions',
  'pages.header': 'Header',
  'pages.update': 'ATUALIZAR',
  'pages.import': 'IMPORTAR',
  'pages.export': 'EXPORTAR',
  'pages.confirm-import': 'CONFIRMAR',
  'pages.filters': 'Filtros',
  'pages.details': 'DETAILS',
  'pages.orders': 'ORDERS',
  'pages.rating': 'Rating',
  'pages.price': 'Price',
  'pages.import-drivers': 'Importar Condutores',
  'pages.import-vehicles': 'Importar Veículos',
  'pages.select-file': 'Selecionar arquivo',
  'pages.download-example': 'Baixar planilha exemplo',
  'pages.ingredients': 'Ingredients',
  'pages.is-vegan': 'Is Vegan',
  'pages.order-status': 'Order Status',
  'pages.bake-progress': 'Bake Progress',
  'pages.popularity': 'Popularity',
  'pages.invalid': 'Inválido',
  'pages.comments': 'Comments',
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.mailing-info':
    'Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.',
  'pages.invoice-info':
    'Invoice template has an inline styled version for usage outside of the project as well as React version.',
  'pages.react-version': 'React Version',
  'pages.inline-version': 'Inline Styled Html Version',
  'pages.like': 'Like',
  'pages.likes': 'Likes',
  'pages.details-title': 'Details',
  'pages.comments-title': 'Comments',
  'pages.questions-title': 'Questions',
  'pages.similar-projects': 'Similar Projects',
  'pages.send': 'Send',
  'pages.addComment': 'Add a comment',
  'pages.location': 'Location',
  'pages.responsibilities': 'Responsibilities',
  'pages.photos': 'Photos',
  'pages.who-to-follow': 'Who to Follow',
  'pages.follow': 'FOLLOW',
  'pages.followers': 'FOLLOWERS',
  'pages.recent-posts': 'Recent Posts',
  'pages.profile': 'PROFILE',
  'pages.friends': 'FRIENDS',
  'pages.images': 'IMAGES',
  'pages.purchase': 'PURCHASE',
  'pages.price.developer': 'DEVELOPER',
  'pages.price.team': 'TEAM',
  'pages.price.enterprise': 'ENTERPRISE',
  'pages.price.twofactorauthentication': 'Two factor authentication',
  'pages.price.teampermissions': 'Team permissions',
  'pages.price.245Support': '24/5 Support',
  'pages.price.247Support': '24/7 Support',
  'pages.price.useractionsauditlog': 'User actions audit log',
  'pages.prices.featurecomparison': 'Feature Comparison',
  'pages.prices.pricecomparison': 'Price Comparison',
  'pages.period': 'Período',
  'pages.enabled-station': 'Habilitado para abastecimento',
  'pages.disabled-station': 'Desabilitado para abastecimento',
  'pages.session-ended':
    'Sua sessão foi encerrada, por favor faça login novamente.',
  'pages.session-ended-title': 'Aviso!',
  'pages.session-ended-button': 'Ok',
  'pages.weekly': 'Semanal',
  'pages.fortnightly': 'Quinzenal',
  'pages.monthly': 'Mensal',
  'pages.driver': 'Condutor',
  'pages.recipient': 'Beneficiário',
  'pages.balance-sharing-report': 'Créditos Adicionados',
  'pages.efficiency': 'Eficiência',
  'pages.system': 'Sistema',
  'pages.licensePlate': 'Placa',
  'pages.driversLicense': 'N° Registro CNH',
  'pages.driversLicenseExpirationDate': 'Data validade CNH',
  'pages.success': 'Sucesso',
  'pages.password': 'Senha',
  'pages.new-password': 'Nova senha',
  'pages.confirm-new-password': 'Confirmar nova senha',
  'pages.logout-warning':
    'Após efetuar a troca da senha será realizado o logout automático no sistema, sendo necessário realizar o login novamente com a nova senha.',
  'pages.password-requirements': 'Requisitos de senha',
  'pages.screen': 'Tela',
  'pages.permission': 'Permissão',
  'pages.role': 'Papel',
  'pages.access': 'Acesso',
  'pages.permissions': 'Permissões',
  'pages.discountValue': 'Desconto',
  'pages.fullValue': 'Valor sem desconto',
  'pages.balance-sharing-type': 'Tipo de distribuição de saldo',
  'pages.distribution-type-warning':
    'Ao alterar o tipo de distribuição, todo o saldo distribuido será estornado para empresa. Deseja continuar?',
  'pages.export-pdf': 'EXPORTAR - PDF',
  'pages.export-excel': 'EXPORTAR - EXCEL',
  'pages.deposit': 'Depósito',
  'pages.undistributed-balance': 'Não distribuído',
  'pages.distributed-balance': 'Distribuído',
  'pages.payment-type-pix': 'PIX',
  'pages.payment-type-bill': 'Boleto',
  'pages.payment-type-transfer': 'Transferência',
  'pages.qrCode': 'Código QR',
  'pages.QRCode': 'QR Code',
  'pages.pixCopyAndPaste': 'Pix Copia e Cola',
  'pages.pix-notice':
    'Atenção! Os pagamentos podem levar até 10 minutos para serem processados.',
  'pages.expense-center': 'Centro de Custo',
  'pages.expense-centers': 'Centros de Custo',
  'pages.shared-credit': 'Crédito Compartilhado',
  'pages.wallets': 'Carteiras',
  'pages.edit-limit': 'Editar Limite',
  'pages.current-limit': 'Limite atual',
  'pages.enable-refuel': 'Habilitar Abastecimento',
  'pages.litroz-anywhere': 'Litroz Livre',
  'pages.anywhereChargedFee': 'Taxa Litroz Livre',
  'pages.anywhereChargedFeeType': 'Tipo de Taxa Litroz Livre',
  'pages.show-password': 'Exibir senha',
  'pages.hide-password': 'Ocultar senha',
  'pages.tax-receipt': 'Cupom Fiscal',
  'pages.view-map': 'Ver no mapa',
  'pages.tanks': 'Tanques',
  'pages.internal-tank': 'Tanque',
  'pages.general-tank': 'Geral',
  'pages.general-tank-description':
    'Permite o abastecimento em todos o tanques da empresa.',
  'pages.limit': 'Limite',
  'pages.restore-balance': 'Restaurar Saldo',
  'pages.sure-to-restore-balance':
    'Tem certeza que deseja restaurar o saldo de {value} litros do {element}?',
  'pages.type': 'Tipo',
  'pages.fuel-management': 'Tanque Interno',
  'pages.travelled-distance': 'KM Rodado',
  'pages.efficiencyFieldValue': 'Variancia de Eficiência (%)',
  'pages.origin': 'Origem',
  'pages.destiny': 'Destino',
  'pages.calculate-route': 'Calcular Rota',
  'pages.clear': 'Limpar',
  'pages.tag': 'Tag',
  'pages.manager': 'Responsável',
  'pages.affiliated-chain': 'Rede Litroz',
  'pages.registration': 'Cadastros',
  'pages.distance': 'Distância',

  'bills.weekly': 'Fatura Semanal',
  'bills.fortnightly': 'Fatura Quinzenal',
  'bills.monthly': 'Fatura Mensal',
  'bills.total': 'Total dessa fatura',

  'balanceSharing.total': 'Total distribuido',
  'balanceSharing.title': 'Créditos Adicionados',

  'litroz.more-info': 'Para mais informações entre em contato com a Litroz:',
  'litroz.phone': 'Telefone: (31) 3956-0887',
  'litroz.address':
    'Rua dos Otoni, 428 - Santa Efigênia, Belo Horizonte - MG, 30240-310',

  /* 06.Applications */

  /* 06.01.Chat */
  'chat.messages': 'Messages',
  'chat.contacts': 'Contacts',
  'chat.saysomething': 'Say something..',

  /* 06.02.Survey */
  'survey.delete': 'Delete',
  'survey.edit': 'Edit',
  'survey.add-new': 'ADD NEW',
  'survey.add-new-title': 'Add New Survey',
  'survey.title': 'Title',
  'survey.category': 'Category',
  'survey.label': 'Label',
  'survey.status': 'Status',
  'survey.cancel': 'Cancel',
  'survey.submit': 'Submit',
  'survey.another-action': 'Another action',
  'survey.display-options': 'Filtros',
  'survey.orderby': 'Order By : ',
  'survey.all-surveys': 'All Surveys',
  'survey.completed-surveys': 'Completed Surveys',
  'survey.categories': 'Categories',
  'survey.active-surveys': 'Active Surveys',
  'survey.labels': 'Labels',

  /* 06.03.Todo */
  'todo.add-new': 'ADD NEW',
  'todo.add-new-title': 'Add New Todo',
  'todo.title': 'Title',
  'todo.detail': 'Detail',
  'todo.category': 'Category',
  'todo.label': 'Label',
  'todo.status': 'Status',
  'todo.cancel': 'Cancel',
  'todo.submit': 'Submit',
  'todo.action': 'Action',
  'todo.another-action': 'Another action',
  'todo.display-options': 'Filtros',
  'todo.orderby': 'Order By : ',
  'todo.all-tasks': 'All Tasks',
  'todo.pending-tasks': 'Pending Tasks',
  'todo.completed-tasks': 'Completed Tasks',
  'todo.categories': 'Categories',
  'todo.labels': 'Labels',

  /* 07.UI */

  /* 07.01.Alerts */
  'alert.rounded': 'Rounded Alert',
  'alert.react-notifications': 'React Notifications',
  'alert.outline': 'Outline',
  'alert.primary': 'Primary',
  'alert.secondary': 'Secondary',
  'alert.info': 'Info',
  'alert.success': 'Success',
  'alert.warning': 'Warning',
  'alert.error': 'Error',
  'alert.filled': 'Filled',
  'alert.primary-text': 'This is a primary alert—check it out!',
  'alert.secondary-text': 'This is a secondary alert—check it out!',
  'alert.success-text': 'This is a success alert—check it out!',
  'alert.danger-text': 'This is a danger alert—check it out!',
  'alert.warning-text': 'This is a warning alert—check it out!',
  'alert.info-text': 'This is a info alert—check it out!',
  'alert.light-text': 'This is a light alert—check it out!',
  'alert.dark-text': 'This is a dark alert—check it out!',
  'alert.default': 'Default Alert',
  'alert.dismissing': 'Dismissing Alert',
  'alert.dismissing-text':
    'Holy guacamole! You should check in on some of those fields below.',
  'alert.dismissing-without-animate-text':
    'I am an alert and I can be dismissed without animating!',

  /* 07.02.Badges */
  'badge.sizes': 'Sizes',
  'badge.colors': 'Colors',
  'badge.outline': 'Outline',
  'badge.links': 'Links',
  'badge.counter-badges': 'Counter Badges',
  'badge.bootstrap-default': 'Bootstrap Default',
  'badge.primary': 'Primary',
  'badge.secondary': 'Secondary',
  'badge.success': 'Success',
  'badge.danger': 'Danger',
  'badge.warning': 'Warning',
  'badge.info': 'Info',
  'badge.light': 'Light',
  'badge.dark': 'Dark',

  /* 07.03.Buttons */
  'button.default': 'Bootstrap Default',
  'button.colors': 'Colors',
  'button.rounded': 'Rounded',
  'button.outline': 'Outline',
  'button.states': 'States',
  'button.sizes': 'Sizes',
  'button.button-groups': 'Button Groups',
  'button.large-button': 'Large Button',
  'button.small-button': 'Small Button',
  'button.extra-small-button': 'Extra Small Button',
  'button.block-button': 'Block Button',
  'button.active': 'Active',
  'button.disabled': 'Disabled',
  'button.basic': 'Basic',
  'button.toolbar': 'Toolbar',
  'button.nesting': 'Nesting',
  'button.vertical-variation': 'Vertical Variation',
  'button.checkbox-radio-button': 'Checkbox and Radio Button',
  'button.checkbox': 'Checkbox',
  'button.radio': 'Radio',
  'button.radio-button': 'Radio Button',
  'button.primary': 'Primary',
  'button.secondary': 'Secondary',
  'button.success': 'Success',
  'button.danger': 'Danger',
  'button.warning': 'Warning',
  'button.info': 'Info',
  'button.light': 'Light',
  'button.dark': 'Dark',
  'button.states-text':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.',
  'button.click-here': 'Click Here',
  'button.states-text-alternate':
    'This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.',
  'button.primary-link': 'Primary Link',
  'button.link': 'Link',
  'button.primary-button': 'Primary Button',
  'button.button': 'Button',
  'button.left': 'Left',
  'button.middle': 'Middle',
  'button.right': 'Right',
  'button.dropdown': 'Dropdown',
  'button.dropdown-link': 'Dropdown Link',
  'button.buy-with-pix': 'Comprar com PIX',
  'button.buy': 'Comprar',
  'button.close': 'Fechar',

  /* 07.04.Cards */
  'cards.icon-card': 'Icon Card',
  'cards.image-card': 'Image Card',
  'cards.image-overlay-card': 'Image Overlay Card',
  'cards.image-card-list': 'Image Card List',
  'cards.tab-card': 'Tab Card',
  'cards.user-card': 'User Card',
  'cards.title-pix': 'PIX',
  'cards.description-pix': 'Solicitar compra de créditos via PIX',
  'cards.title-slip': 'Boleto',
  'cards.description-slip': 'Solicitar compra de créditos via Boleto',
  'cards.tilte-credit-transfer': 'Transferência',
  'cards.description-credit-transfer':
    'Solicitar compra de créditos via Transferência',

  /* 07.05.Carousel */
  'carousel.basic': 'Carousel Basic',
  'carousel.single': 'Carousel Single',
  'carousel.without-controls': 'Carousel Without Controls',

  /* 07.06.Charts */
  'charts.line': 'Line Chart',
  'charts.polar': 'Polar Chart',
  'charts.area': 'Area Chart',
  'charts.scatter': 'Scatter Chart',
  'charts.bar': 'Bar Chart',
  'charts.radar': 'Radar Chart',
  'charts.pie': 'Pie Chart',
  'charts.doughnut': 'Doughnut Chart',
  'charts.shadow': 'Shadow',
  'charts.no-shadow': 'No Shadow',

  /* 07.07.Collapse */
  'collapse.basic': 'Basic',
  'collapse.toggle': 'Toggle',
  'collapse.accordion': 'Accordion',
  'collapse.controlled': 'Controlled',
  'collapse.uncontrolled': 'Uncontrolled',

  /* 07.08.Dropdowns */
  'dropdowns.basic': 'Basic',
  'dropdowns.controlled': 'Controlled',
  'dropdowns.uncontrolled': 'Uncontrolled',
  'dropdowns.dropdown': 'Dropdown',
  'dropdowns.header': 'Header',
  'dropdowns.action': 'Action',
  'dropdowns.another-action': 'Another Action',
  'dropdowns.right': 'Right',
  'dropdowns.left': 'Left',
  'dropdowns.drop-directions': 'Drop Directions',
  'dropdowns.dropright': 'Dropright',
  'dropdowns.dropleft': 'Dropleft',
  'dropdowns.small-button': 'Small Button',
  'dropdowns.large-button': 'Large Button',
  'dropdowns.sizing': 'Sizing',
  'dropdowns.split-button': 'Split Button Dropdowns',
  'dropdowns.dropup': 'Dropup',
  'dropdowns.statement': 'Extrato',
  'dropdowns.wallets': 'Carteiras',

  'dropdowns.edit': 'Editar',
  'dropdowns.refuel': 'Abastecer',
  'dropdowns.remove': 'Remover',
  'dropdowns.transfer': 'Transferir Créditos',
  'dropdowns.efficiency-history': 'Hist. Eficiência',
  'dropdowns.activate': 'Ativar',
  'dropdowns.inactivate': 'Inativar',
  'dropdowns.recover': 'Recuperar',
  'dropdowns.convert-to-recipient': 'Tornar beneficiário',
  'dropdowns.convert-to-driver': 'Tornar condutor',
  'dropdowns.recover-password': 'Recuperar senha',
  'dropdowns.edit-branch': 'Editar filial',

  /* 07.09.Editors */
  'editors.draftjs': 'Draft.js',
  'editors.quill-standart': 'Quill Standard',
  'editors.quill-bubble': 'Quill Bubble',

  /* 07.10.Forms */
  'forms.basic': 'Basic',
  'forms.email': 'E-mail',
  'forms.email-muted': "We'll never share your email with anyone else.",
  'forms.password': 'Password',
  'forms.password-confirm': 'Confirm Password',
  'forms.submit': 'Submit',
  'forms.horizontal': 'Horizontal',
  'forms.radios': 'Radios',
  'forms.first-radio': 'First radio',
  'forms.second-radio': 'Second radio',
  'forms.third-radio-disabled': 'Third disabled radio',
  'forms.checkbox': 'Checkbox',
  'forms.signin': 'Sign in',
  'forms.top-labels-over-line': 'Top Labels Over Line',
  'forms.tags': 'Tags',
  'forms.date': 'Date',
  'forms.state': 'State',
  'forms.top-labels-in-input': 'Top Labels In Input',
  'forms.email-u': 'E-MAIL',
  'forms.password-u': 'PASSWORD',
  'forms.tags-u': 'TAGS',
  'forms.date-u': 'DATE',
  'forms.state-u': 'STATE',
  'forms.grid': 'Form Grid',
  'forms.address': 'Address',
  'forms.address2': 'Address 2',
  'forms.city': 'City',
  'forms.city-message': 'Please select a city!',
  'forms.state-message': 'Please select a state!',
  'forms.zip': 'Zip',
  'forms.signup': 'Signup',
  'forms.inline': 'Inline',
  'forms.validation-availity': 'Availity Reactstrap Validation',
  'forms.validation-formik': 'Formik Validation',
  'forms.default': 'Default',
  'forms.firstname': 'First name',
  'forms.firstname-message': 'Please enter your first name!',
  'forms.lastname': 'Last name',
  'forms.lastname-message': 'Please enter your last name!',
  'forms.name': 'Name',
  'forms.to': 'Para',
  'forms.balance-distributed': 'Saldo distribuído',
  'forms.transfer-from': 'Transferir de',
  'forms.email-info-message':
    'Esse email será usado para o envio e recuperação de senha do usuário.',

  /* 07.11.Form Components */
  'form-components.custom-inputs': 'Custom Inputs',
  'form-components.checkboxes': 'Checkboxes',
  'form-components.radios': 'Radios',
  'form-components.inline': 'Inline',
  'form-components.react-select': 'React Select',
  'form-components.state-single': 'State Single',
  'form-components.state-multiple': 'State Multiple',
  'form-components.react-autosuggest': 'React Autosuggest',
  'form-components.date-picker': 'Date Picker',
  'form-components.date': 'Date',
  'form-components.date-range': 'Date Range',
  'form-components.date-with-time': 'Date with Time',
  'form-components.embedded': 'Embedded',
  'form-components.dropzone': 'Dropzone',
  'form-components.drop-files-here': 'Drop Files Here',
  'form-components.tags': 'Tags',
  'form-components.switch': 'Switch',
  'form-components.primary': 'Primary',
  'form-components.secondary': 'Secondary',
  'form-components.primary-inverse': 'Primary Inverse',
  'form-components.secondary-inverse': 'Secondary Inverse',
  'form-components.slider': 'Slider',
  'form-components.double-slider': 'Double Slider',
  'form-components.single-slider': 'Single Slider',
  'form-components.rating': 'Rating',
  'form-components.interactive': 'Interactive',
  'form-components.readonly': 'Readonly',
  'form-components.type-a-cake': 'Type a Cake',
  'form-components.start': 'Start',
  'form-components.end': 'End',
  'form-components.tables': 'Tables',
  /* 07.12.Icons */
  'icons.simplelineicons': 'Simple Line Icons',
  'icons.iconsmind': 'Iconsmind Icons',

  /* 07.13.Input Groups */
  'input-groups.basic': 'Basic',
  'input-groups.sizing': 'Sizing',
  'input-groups.small': 'Small',
  'input-groups.default': 'Default',
  'input-groups.large': 'Large',
  'input-groups.checkboxes-and-radios': 'Checkboxes and radios',
  'input-groups.multiple-inputs': 'Multiple Inputs',
  'input-groups.first-and-last-name': 'First and last name',
  'input-groups.multiple-addons': 'Multiple Addons',
  'input-groups.button-addons': 'Button Addons',
  'input-groups.button': 'Button',
  'input-groups.buttons-with-dropdowns': 'Buttons with Dropdowns',
  'input-groups.dropdown': 'Dropdown',
  'input-groups.header': 'Header',
  'input-groups.action': 'Action',
  'input-groups.another-action': 'Another Action',
  'input-groups.custom-select': 'Custom Select',
  'input-groups.options': 'Options',
  'input-groups.choose': 'Choose...',
  'input-groups.custom-file-input': 'Custom File Input',

  /* 07.14.Jumbotron */
  'jumbotron.hello-world': 'Hello, world!',
  'jumbotron.lead':
    'This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.',
  'jumbotron.lead-detail':
    'It uses utility classes for typography and spacing to space content out within the larger container.',
  'jumbotron.learn-more': 'Learn more',

  /* 07.15.Modal */
  'modal.basic': 'Basic',
  'modal.modal-title': 'Modal title',
  'modal.launch-demo-modal': 'Launch Demo Modal',
  'modal.scrolling-long-content': 'Scrolling Long Content',
  'modal.backdrop': 'Backdrop',
  'modal.backdrop-value': 'Backdrop value',
  'modal.right-modal': 'Right Modal',
  'modal.launch-right-modal': 'Launch Right Modal',
  'modal.nested-modal': 'Nested Modal',
  'modal.size': 'Size',
  'modal.launch-large-modal': 'Launch Large Modal',
  'modal.launch-small-modal': 'Launch Small Modal',
  'modal.add-new-administrator': 'Adicionar novo administrador',
  'modal.add-new-vehicle': 'Adicionar novo veículo',
  'modal.add-new-container': 'Adicionar novo recipiente',
  'modal.add-new-expense-center': 'Adicionar novo centro de custo',
  'modal.add-new-driver': 'Adicionar novo condutor',
  'modal.add-new-recipient': 'Adicionar novo beneficiário',
  'modal.edit-administrator': 'Editar administrador',
  'modal.edit-vehicle': 'Editar veículo',
  'modal.edit-container': 'Editar recipiente',
  'modal.edit-expense-center': 'Editar centro de custo',
  'modal.edit-driver': 'Editar condutor',
  'modal.edit-recipient': 'Editar beneficiário',
  'modal.add-branch': 'Adicionar nova filial',
  'modal.edit-branch': 'Editar filial',
  'modal.recovery-password': 'Recuperar senha',
  'modal.edit-user-branch': 'Editar filial do usuário',
  'modal.warning': 'Aviso',
  'modal.more-filter': 'Mais filtros',
  'modal.transfer-credit': 'Transferir crédito',
  'modal.volume': 'Volume (litros)',
  'modal.confirm-transfer-credit':
    'Tem certeza que deseja transferir o crédito de {transferFrom} para {transferTo}?',

  /* 07.16.Navigation */
  'nav.basic': 'Nav Basic',
  'nav.active': 'Active',
  'nav.disabled': 'Disabled',
  'nav.disabled-link': 'Disabled Link',
  'nav.link': 'Link',
  'nav.longer-link': 'Longer nav link',
  'nav.another-link': 'Another Link',
  'nav.right': 'Right',
  'nav.dropdown': 'Dropdown',
  'nav.header': 'Header',
  'nav.action': 'Action',
  'nav.another-action': 'Another Action',
  'nav.horizontal-alignment': 'Nav Horizontal Alignment',
  'nav.vertical-alignment': 'Nav Vertical Alignment',
  'nav.pills': 'Nav Pills',
  'nav.fill-justify': 'Nav Fill and Justify',
  'nav.pills-dropdowns': 'Nav Pills with Dropdowns',
  'nav.pagination-basic': 'Pagination Basic',
  'nav.pagination-sizing': 'Pagination Sizing',
  'nav.large': 'Large',
  'nav.small': 'Small',
  'nav.center': 'Center',
  'nav.pagination-alignment': 'Pagination Alignment',
  'nav.breadcrumb-basic': 'Breadcrumb Basic',

  /* 07.17.Popover & Tooltip */
  'popover-tooltip.popover': 'Popover',
  'popover-tooltip.tooltip': 'Tooltip',

  /* 07.18.Sortable */
  'sortable.columns': 'Sorting Columns',
  'sortable.basic': 'Basic',
  'sortable.handles': 'Handles',

  /* 07.19.Maps */
  'maps.google': 'Google',
  'maps.yandex': 'Yandex',

  /* 07.20.Tables */
  'table.bootstrap-tables': 'Bootstrap Tables',
  'table.bootstrap-basic': 'Basic Table',
  'table.bootstrap-striped': 'Striped Rows',
  'table.bootstrap-bordered': 'Bordered Table',
  'table.bootstrap-borderless': 'Borderless Table',
  'table.bootstrap-hoverable': 'Hoverable Rows',
  'table.bootstrap-responsive': 'Responsive Table',
  'table.react-tables': 'React Tables',
  'table.react-pagination': 'Pagination',
  'table.react-scrollable': 'Scrollable',
  'table.react-advanced': 'Filter, Length and Jump',
  'table.scheduleType': 'Tipo de agendamento',
  'table.shareAmount': 'Valor compartilhado',
  'table.interval': 'Intervalo',
  'table.lastExecuted': 'Última execução',
  'table.nextExecution': 'Próxima execução',
  'table.status': 'Status',

  /* 07.21.Wizards */
  'wizard.step-name-1': 'Step 1',
  'wizard.step-name-2': 'Step 2',
  'wizard.step-name-3': 'Step 3',
  'wizard.step-desc-1': 'First step description',
  'wizard.step-desc-2': 'Second step description',
  'wizard.step-desc-3': 'Third step description',
  'wizard.content-1': 'Step content for first step.',
  'wizard.content-2': 'Step content for second step.',
  'wizard.content-3': 'Last step content!',
  'wizard.content-thanks': 'Thank You!',
  'wizard.next': 'Next',
  'wizard.prev': 'Back',
  'wizard.registered': 'Your registration completed successfully!',
  'wizard.async': 'Async save for 3 seconds!',

  'organizations.aggregate': 'Agregado',
  'date.range': 'Intervalo de datas',
  'api.error': 'Não foi possível carregar estes dados',
  'api.empty': 'Não foram encontrados dados para serem exibidos',

  'fuel.ethanol': 'Etanol',
  'fuel.premiumEthanol': 'Etanol Aditivado',
  'fuel.gas': 'Gasolina',
  'fuel.premiumGas': 'Gasolina Aditivada',
  'fuel.carrierNaturalGas': 'Gás Natural Veicular',
  'fuel.diesel': 'Diesel S10',
  'fuel.dieselS500': 'Diesel S500',
  'fuel.arla': 'Arla',
  'fuel.flex': 'Flex',

  'month-year.1': 'Janeiro {year}',
  'month-year.2': 'Fevereiro {year}',
  'month-year.3': 'Março {year}',
  'month-year.4': 'Abril {year}',
  'month-year.5': 'Maio {year}',
  'month-year.6': 'Junho {year}',
  'month-year.7': 'Julho {year}',
  'month-year.8': 'Agosto {year}',
  'month-year.9': 'Setembro {year}',
  'month-year.10': 'Outubro {year}',
  'month-year.11': 'Novembro {year}',
  'month-year.12': 'Dezembro {year}',

  'month.1': 'Janeiro',
  'month.2': 'Fevereiro',
  'month.3': 'Março',
  'month.4': 'Abril',
  'month.5': 'Maio',
  'month.6': 'Junho',
  'month.7': 'Julho',
  'month.8': 'Agosto',
  'month.9': 'Setembro',
  'month.10': 'Outubro',
  'month.11': 'Novembro',
  'month.12': 'Dezembro',

  'week-day.monday': 'Segunda-feira',
  'week-day.tuesday': 'Terça-feira',
  'week-day.wednesday': 'Quarta-feira',
  'week-day.thursday': 'Quinta-feira',
  'week-day.friday': 'Sexta-feira',
  'week-day.saturday': 'Sábado',
  'week-day.sunday': 'Domingo',

  'transaction.success': 'Sucesso',
  'transaction.failure': 'Falha',
  'transaction.litroz': 'Litroz',
  'transaction.fuel-management': 'Tanque Interno',

  'transaction.file-name': 'Transações',
  'balace-sharings.file-name': 'creditos-adicionados',
  'efficiency-history.file-name': 'historico-eficiencia',
  'user-statements.file-name': 'extrato-de-usuario',
  'vehicle-statements.file-name': 'extrato-de-veiculo',
  'recipient-statements.file-name': 'extrato-de-beneficiario',
  'driver.file-name': 'condutores',
  'vehicle.file-name': 'veiculos',
  'deposit.file-name': 'depositos',
  'container.file-name': 'recipientes',
  'recipient.file-name': 'beneficiarios',
  'purchase.file-name': 'Compras',
  'branch.file-name': 'Filiais',

  /* 08. Entries */

  /* 08.01.Driver */
  'driver.firstName': 'Nome',
  'driver.lastName': 'Sobrenome',
  'driver.identifier': 'CPF',
  'driver.email': 'E-mail',
  'driver.phone': 'Telefone',
  'driver.password': 'Senha de aplicativo',
  'driver.authenticateTransaction': 'Senha de transação',
  'driver.driversLicenseExpirationDate': 'Data validade CNH',
  'driver.driversLicense': 'N° Registro CNH',

  'user.category.employee': 'Condutor',
  'user.category.contractor': 'Beneficiário',
  'user.category.administrator': 'Administrador',
  'user.category.operator': 'Operador',

  'distribution.title': 'Distribuição de saldo',
  'distribution.available': 'Saldo disponível: {balance}',

  'wallets.title': 'Carteiras',

  'data.approved': 'Aprovada',
  'data.pending': 'Pendente',
  'data.rejected': 'Rejeitada',
  'data.paid': 'Pago',
  'data.cancelled': 'Cancelada',
  'data.active': 'ativo',
  'data.inactive': 'inativo',
  'data.yes': 'Sim',
  'data.no': 'Não',

  'column.date': 'Data',
  'column.balance': 'Saldo',
  'column.total': 'Total',
  'column.price': 'Preço',
  'column.liters': 'Litros',
  'column.value': 'Valor',

  'password.rule.minimum-characters': 'Mínimo 8 caracteres',
  'password.rule.uppercase-lowercase-letters': 'Letras maiúsculas e minúsculas',
  'password.rule.alphanumeric': 'Letras e números',
  'password.rule.match': 'Senhas não coincidem',

  'password.error.not-match': 'Senhas não coincidem.',
  'password.error.min-length': 'A senha deve possuir no mínimo 8 caracteres.',
  'password.error.alphanumeric': 'A senha deve possuir letras e números.',
  'password.error.lower-and-upperCase':
    'A senha deve possuir letras minúsculas e maiúsculas.',

  'error.access-deined': 'Acesso negado',
  'error.access-deined-text':
    'Você não possui permissão acesso a essa tela. Entre em contato com o administrador pra solicitar permissão.',

  'input.employee': 'Empregado',
  'input.vehicle': 'Veículo',
};

/* 02.User Login, Logout, Register */
// 'user.login-title': 'Login',
// 'user.register': 'Cadastrar',
// 'user.forgot-password': 'Esqueci minha senha',
// 'user.email': 'E-mail',
// 'user.password': 'Senha',
// 'user.forgot-password-question': 'Esqueceu sua senha?',
// 'user.fullname': 'Full Name',
// 'user.login-button': 'ENTRAR',
// 'user.register-button': 'REGISTER',
// 'user.reset-password-button': 'RESET',
// 'user.buy': 'BUY',
// 'user.username': 'Usuário',
